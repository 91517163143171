


























































































































































































































































































































































import {
  computed,
  defineComponent,
  ref,
  Ref,
  watch,
  onMounted,
  onUnmounted,
  reactive
} from '@vue/composition-api';
import { useUserActions, useUserGetters } from '@/store';
import './styles/module.scss';
import { OperationMode } from '@/constants/operationMode';
import Bar from './components/TimelineBar.vue';

export const enum ProgramActivityStatus {
  NeedSetup = 'need_setup',
  CompleteSetup = 'complete_setup',
  Standard = 'standard'
}

export interface ITimeLineItem {
  step: string;
  unlocked: boolean;
  category: 'project' | 'internship';
  tooltip: string;
  status?: ProgramActivityStatus;
}

export default defineComponent({
  name: 'Guide',
  components: {
    'guide-bar': Bar,
    Loading: () => import('@/components/Loading.vue'),
    MModule: () => import('@/components/molecules/m-module.vue'),
    CheckoutOverlay: () => import('./components/CheckoutOverlay.vue'),
    Overlay: () => import('./components/Overlay.vue'),
    OnboardDialog: () => import('@/components/molecules/m-onboarding.vue'),
    InstructPopup: () => import('@/components/molecules/instruct-popup.vue'),
    MBasicUserInfo: () => import('@/components/molecules/m-basic-user-info-dialog.vue')
  },
  props: {
    studentId: {
      type: String,
      required: false,
      default: ''
    },
    programId: {
      type: String,
      required: false,
      default: ''
    },
    isMonitor: {
      type: Boolean,
      default: false
    },
    page: {
      type: Number,
      required: false,
      default: 1
    },
    monitorOrganizerView: {
      type: Boolean,
      required: false,
      default: false
    },
    openAsWidget: {
      type: Boolean,
      required: false,
      default: false
    },
    previewView: {
      type: Boolean,
      required: false,
      default: false
    },
    dialogOpen: {
      required: false,
      type: Boolean,
      default: false
    },
    program_name: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: ''
    }
  },

  setup(_props: any, ctx: any) {
    const isDisplayBasicUserInfoPopup = ref(false);
    const basicUserInfo = reactive({
      firstName: '',
      lastName: '',
      email: '',
      filled: false
    });
    const windowWidth = ref(window.innerWidth);
    const updateWindowWidth = () => {
      const newWidth = window.innerWidth;
      windowWidth.value = newWidth;
    };
    const resizeObserver = new ResizeObserver(updateWindowWidth);
    onMounted(() => {
      updateWindowWidth(); // Initial width
      window.addEventListener('resize', updateWindowWidth);
      resizeObserver.observe(document.body);
    });
    onUnmounted(() => {
      window.removeEventListener('resize', updateWindowWidth);
      resizeObserver.disconnect();
    });
    const isShowCTASnackbar = ref(false);
    const isShowTitleSnackbar = ref(false);
    const snackbarTimer = ref(5000);
    const isShowInstructPopup = ref(false);
    const popUp = ref(false);
    const ctaButtons = [
      { label: 'Signup', routeName: 'signup' },
      {
        label: 'Get Teacher Plan',
        routeName: 'TeacherLanding'
      },
      {
        label: 'Get School Quote',
        routeName: 'SchoolLanding'
      }
    ];
    const images = [
      {
        name: '1',
        url: 'https://images.unsplash.com/photo-1544044307-d09d2ef95e18?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
      },
      {
        name: '2',
        url: 'https://images.unsplash.com/photo-1543938601-28013263c758?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
      },
      {
        name: '3',
        url: 'https://images.unsplash.com/photo-1544126663-1a533ffe0afb?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
      },
      {
        name: '4',
        url: 'https://images.unsplash.com/photo-1544614342-c48ab91d79fc?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
      },
      {
        name: '5',
        url: 'https://images.unsplash.com/photo-1544979407-1204ff29f490?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
      }
    ];
    const isVisitor = ref(false);
    const isDraft = ref(false);
    const isNotAuthenticated = ref(false);
    const isSaveProgress = ref(false);
    let { programId } = _props;
    const { openAsWidget, monitorOrganizerView } = _props;
    const { getUser, getUserType, getProgramDoc } = useUserGetters([
      'getUser',
      'getUserType',
      'getProgramDoc'
    ]);
    const {
      fetchProgramById,
      fetchDraft,
      fetchUserType,
      fetchStudentById,
      getProgramByNameOrIdOrSharableUrl,
      createStudent,
      createAnonymousStudent,
      getDataWithShareableUrl,
      updateCompleted,
      fetchUserById,
      loginUser,
      getUserByKeyAndValue
    } = useUserActions([
      'fetchProgramById',
      'fetchDraft',
      'fetchUserType',
      'fetchStudentById',
      'getProgramByNameOrIdOrSharableUrl',
      'createStudent',
      'createAnonymousStudent',
      'getDataWithShareableUrl',
      'updateCompleted',
      'fetchUserById',
      'loginUser',
      'getUserByKeyAndValue'
    ]);
    const user: any = computed({
      get: () => getUser.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const programDoc: Ref<{
      data: Record<string, any>; // Gives access to Document
    }> = ref({
      data: {}
    });
    /** Get program by shareable url */
    const programNameParams: any = ref(false);
    const programID: any = computed({
      get: () => programId,
      set: newVal => {
        programId = newVal;
      }
    });
    watch(
      () => _props.programId,
      newVal => {
        programId = newVal;
      },
      { deep: true }
    );
    if (localStorage.getItem('apollo-token')) {
      programNameParams.value = false;
    }

    const getDomainByEnvironmentName = () => {
      let domain;
      if (process.env.VUE_APP_NODE_ENV === 'development') {
        domain = 'develop.pilotcity.com';
      } else if (process.env.VUE_APP_NODE_ENV === 'stage') {
        domain = 'stage.pilotcity.com';
      } else {
        domain = 'pilotcity.com';
      }
      return domain;
    };
    const setProgramData = async response => {
      programDoc.value.data = {
        adks: [],
        ...response
      };
    };

    const setAuthUserData = async () => {
      if (user?.value === null) {
        const result = await fetchUserById({
          user_id: programDoc?.value?.data?.participant_id,
          public_id: programDoc?.value?.data?._id
        });
        if (result?.data) {
          await loginUser(result?.data);
        }
      }
    };

    const handlePublicProgramFlow = async (user: any, programId: string, programDoc: any) => {
      let studentData;

      if (user?.value && user?.value?.userTypes?.includes('Student')) {
        studentData = await createStudent({
          program_id: programId
        });
      } else {
        studentData = await createAnonymousStudent({
          program_id: programId,
          basic_user_info: basicUserInfo
        });
      }
      // console.log('studentData', studentData);

      const redirectToUrl =
        studentData && studentData?.sharable_url ? `https://${studentData?.sharable_url}` : null;
      if (redirectToUrl) {
        window.location.href = redirectToUrl;
      } else {
        const fetchStudentOptions = {
          program_id: programId,
          user_id: ctx.root.$route.name === 'Monitor' ? _props.studentId : user.value?._id,
          public_id: programDoc?.value?.data?._id
        };
        studentData = await fetchStudentById(fetchStudentOptions);
        // console.log('studentData', studentData);
        if (studentData && studentData?.sharable_url) {
          window.location.href = `https://${studentData?.sharable_url}`;
        } else {
          ctx.root.$router.push({ name: 'portfolio' });
        }
      }
    };

    async function getProgramDataBySharableUrl(checkProgramIsPublished) {
      const urlValue = getDomainByEnvironmentName();
      const typeValue = _props?.type === 'demo-program' ? 'program' : _props?.type;
      const sharable_url = `${urlValue}/${typeValue}/${_props?.program_name}`;
      const response = await getDataWithShareableUrl({
        sharable_url,
        document_type: _props?.type === 'public-program' ? 'Student' : 'Program'
      });
      if (response?.isPublic && _props?.type === 'demo-program') {
        programId = response?._id?.toString();
      } else if (_props?.type === 'public-program') {
        if (response?.isPublic) {
          await setProgramData(response);
          await setAuthUserData();
          programId = programDoc.value.data.program_id || programDoc.value.data._id;
        } else if (response?.response?.status === 403) {
          const splitedData = sharable_url?.split('-');
          const programIdValue = splitedData?.[splitedData.length - 1];
          const resp = await getProgramByNameOrIdOrSharableUrl({
            type: 'by_id',
            program_id: programIdValue
          });

          const { getUserType } = useUserGetters(['getUserType']);
          const userType = getUserType.value;

          if (resp?.isPublic || userType === 'organizer') {
            await setProgramData(resp);
            const adks = (programDoc?.value?.data?.adks || []).map(adk => {
              const { completed, ...rest } = adk;
              return rest;
            });
            programDoc.value.data.adks = adks;
            programId = programIdValue;
            isDisplayBasicUserInfoPopup.value = true;
          } else {
            ctx.root.$router.push({ name: 'portfolio' });
          }
        }
      } else if (checkProgramIsPublished && response && !response.published) {
        ctx.root.$router.push({ name: 'landing' });
      } else if (
        (checkProgramIsPublished && response && response.published) ||
        (!checkProgramIsPublished && response && response.published) ||
        (!checkProgramIsPublished && response && !response.published)
      ) {
        await setProgramData(response);
        programId = programDoc.value.data.program_id || programDoc.value.data._id;
        const adks = programDoc?.value?.data?.adks
          ?.filter(adk => adk?.status)
          ?.map((adk, index) => {
            if (adk) {
              const { completed, ...data } = adk;
              data.position = index;
              return data;
            }
            return adk;
          });
        programDoc.value.data.adks = adks;
      }
    }

    let checkProgramIsPublished;
    if (programNameParams.value) {
      checkProgramIsPublished = true;
      getProgramDataBySharableUrl(checkProgramIsPublished);
    }

    const userDoc = ref({
      data: user.value
    });

    async function fetchProgram() {
      if (programId === '') {
        checkProgramIsPublished = false;
        await getProgramDataBySharableUrl(checkProgramIsPublished);
      }
      await fetchUserType({
        program_id: programId,
        public_id: programDoc?.value?.data?.program_id ? programDoc?.value.data?._id : null
      });

      if (_props?.type === 'public-program' || _props?.type === 'demo-program') {
        isNotAuthenticated.value = true;
        await setProgramData(getProgramDoc.value);
        if (programDoc?.value?.data?.isDemo) {
          isShowCTASnackbar.value = true;
        } else {
          isShowCTASnackbar.value = false;
        }
      } else if (!userDoc?.value?.data) {
        isNotAuthenticated.value = true;
      } else if (getUserType.value === 'organizer' && ctx.root.$route.name !== 'Monitor') {
        const draft = await fetchDraft({
          program_id: programId
        });
        isDraft.value = true;
        if (!draft) {
          isDraft.value = false;
          await fetchProgramById({
            program_id: programId
          });
        }
        await setProgramData(getProgramDoc.value);
      } else if (getUserType.value === 'participant' || ctx.root.$route.name === 'Monitor') {
        if (_props?.type === 'public-program') {
          isNotAuthenticated.value = true;
        }
        await fetchStudentById({
          program_id: programId,
          user_id: ctx.root.$route.name === 'Monitor' ? _props.studentId : user.value?._id,
          public_id: programDoc?.value?.data?._id
        });
        await setProgramData(getProgramDoc.value);
        if (_props?.type === 'public-program') {
          if (programDoc?.value?.data?.isDemo) {
            // isShowInstructPopup.value = true;
            isShowCTASnackbar.value = true;
          } else {
            isShowCTASnackbar.value = false;
            // isShowTitleSnackbar.value = true;
          }
        }
      } else {
        const isEmployer = userDoc?.value?.data?.userTypes?.includes('Employer');
        const isStudent = userDoc?.value?.data?.userTypes?.includes('Student');

        if (isStudent) {
          const response = await createStudent({
            program_id: programId
          });
          if (response) {
            localStorage.removeItem('program');
          }
        } else if (isEmployer) {
          const resp = await getProgramByNameOrIdOrSharableUrl({
            type: 'by_id',
            program_id: programId
          });
          isVisitor.value = true;
          if (resp) {
            localStorage.removeItem('program');
          }
        } else {
          const resp = await getProgramByNameOrIdOrSharableUrl({
            type: 'by_id',
            program_id: programId
          });
          isVisitor.value = true;
          if (resp) {
            localStorage.removeItem('program');
          }
        }
        await fetchUserType({
          program_id: programId,
          public_id: programDoc?.value?.data?.program_id ? programDoc?.value.data?._id : null
        });
        await setProgramData(getProgramDoc.value);
      }
    }

    const shouldCloseDisplayOverlay = ref(false);

    const adks = computed(() => {
      return programDoc.value?.data?.adks?.map(adk => adk.name);
    });
    const needsetup = computed(() => {
      return programDoc.value?.data?.adks?.flatMap(adk => (adk.setupRequired ? [adk.name] : []));
    });
    const maxIndex: any = computed(() => {
      return adks.value?.length - 1;
    });
    const pageNumber = computed({
      get: () => Number(ctx.root?.$route?.query?.adk) || Number(0),
      set: newPage => {
        if (newPage <= maxIndex.value && newPage >= 0) {
          if (openAsWidget) {
            ctx.emit('new-page', newPage);
            return;
          }
          shouldCloseDisplayOverlay.value = false;
          ctx.root.$router
            ?.replace({
              params: {
                programId,
                page: newPage.toString()
              },
              query: { ...ctx.root.$route?.query }
            })
            .catch(() => {});
        }
      }
    });
    const redirectByPage = pageVal => {
      if (_props?.type === 'public-program') {
        ctx.root.$router.push({
          name: 'publicProgram',
          params: {
            program_name: _props?.program_name
          },
          query: {
            adk: pageVal
          }
        });
      } else if (_props?.type === 'program') {
        ctx.root.$router.push({
          name: 'program',
          params: {
            program_name: _props?.program_name
          },
          query: {
            adk: pageVal
          }
        });
      } else if (_props?.type === 'program-setup') {
        ctx.root.$router.push({
          name: 'programSetup',
          params: {
            program_name: _props?.program_name
          },
          query: {
            adk: pageVal
          }
        });
      } else if (pageVal !== maxIndex.value) {
        ctx.root.$router.push({
          name: 'guide',
          params: {
            programId
          },
          query: {
            adk: pageVal
          }
        });
      } else {
        popUp.value = true;
      }
    };
    const currentAdk: any = computed(() => {
      return adks.value ? adks.value[Number(ctx.root?.$route?.query?.adk) || Number(0)] : '';
    });
    const adksWithPreviewMode: any = computed(() => {
      // return process.env.VUE_APP_PREVIEW_MODE_ADKS?.split(',') || [];
      return (
        programDoc.value?.data?.adks
          ?.filter(adk => adk?.preview && adk?.status)
          ?.map(adk => adk?.name) || []
      );
    });

    const goNext = () => {
      const newPageNumberValue = pageNumber.value + 1;
      redirectByPage(newPageNumberValue);
    };

    const completeActivity = () => {
      const newPageNumberValue = pageNumber.value + 1;
      if (pageNumber.value === maxIndex.value) {
        popUp.value = true;
      } else {
        redirectByPage(newPageNumberValue);
      }
    };

    function emitMolecule(val) {
      currentAdk.value = val.item.name;
      pageNumber.value = val.index;
      redirectByPage(val.index);
    }
    const previewModeEnabled = computed(() => {
      return _props.previewView === true && getUserType.value === 'participant';
    });
    const currentUnit = computed(() => (adks.value ? adks.value[pageNumber.value] : ''));
    const isInPreviewMode = computed(() => {
      return previewModeEnabled.value && adksWithPreviewMode.value.includes(currentUnit.value);
    });
    const currentUnitRequiresSetup = computed(() => {
      return currentUnit.value && needsetup.value?.includes(currentUnit.value);
    });

    const operationMode = computed(() => {
      if (_props.type === 'public-program' || isVisitor.value) {
        return OperationMode.Participant;
      }
      if (getUserType.value === 'participant') return OperationMode.Participant;
      if (monitorOrganizerView) return OperationMode.Monitor;
      if (getUserType.value === 'organizer') return OperationMode.Setup;
      if (isInPreviewMode.value) return OperationMode.Preview;
      if (isVisitor.value) return OperationMode.Setup;
      return OperationMode.None;
    });
    const shouldDisplayOverlay = computed(() => {
      return (
        operationMode.value === OperationMode.Setup &&
        !currentUnitRequiresSetup.value &&
        !shouldCloseDisplayOverlay.value
      );
    });
    const isNextUnlocked = computed(() => {
      if (programDoc.value?.data) {
        const adksData = programDoc.value?.data?.adks || [];
        const nextAdks = adksData?.slice(Number(pageNumber.value) + 1);
        const filterNextAdks = nextAdks?.filter(adk => adk.status);
        const offerStatus = adksData[pageNumber.value]?.fields?.find(
          f => f.name === 'm-offer-table'
        )?.value?.offerStatus;
        let unlocked = false;

        if (isNotAuthenticated.value === false) {
          // if next adk is offer and offer status false then must be locked untill offer given
          if (adksData[pageNumber.value + 1] && getUserType.value !== 'organizer') {
            const isOfferMolecule = adksData[pageNumber.value + 1]?.fields?.find(
              field => field.name === 'm-offer-table'
            );
            if (isOfferMolecule) {
              if (
                programDoc?.value?.data?.applicationStatus === 'accepted' &&
                programDoc?.value?.data?.offerDetails
              ) {
                return true;
              }
              return false;
            }
          }

          if (adksData.length) {
            switch (adksData[pageNumber.value]?.name) {
              case 'interview':
                unlocked =
                  getUserType.value === 'organizer' ? true : adksData[pageNumber.value]?.completed;
                break;

              case 'offer':
                unlocked =
                  getUserType.value === 'organizer'
                    ? true
                    : adksData[pageNumber.value]?.completed &&
                      programDoc.value?.data.offerDetails &&
                      offerStatus;
                break;

              default:
                unlocked =
                  getUserType.value === 'organizer'
                    ? true
                    : !!adksData[pageNumber.value]?.completed;
                break;
            }
            return unlocked;
          }

          if (
            (adksData.length &&
              adksData[pageNumber.value]?.completed &&
              filterNextAdks?.length >= 1) ||
            getUserType.value === 'organizer'
          ) {
            return true;
          }
        } else if (isNotAuthenticated.value && _props?.type === 'public-program') {
          if (adksData[pageNumber.value + 1]) {
            const isOfferMolecule = adksData[pageNumber.value + 1]?.fields?.find(
              field => field.name === 'm-offer-table'
            );
            if (isOfferMolecule) {
              if (
                programDoc?.value?.data?.applicationStatus === 'accepted' &&
                programDoc?.value?.data?.offerDetails
              ) {
                return true;
              }
              return false;
            }
          }

          if (adksData.length) {
            switch (adksData[pageNumber.value]?.name) {
              case 'interview':
                unlocked = adksData[pageNumber.value]?.completed;
                break;

              case 'offer':
                unlocked =
                  adksData[pageNumber.value]?.completed &&
                  programDoc.value?.data.offerDetails &&
                  offerStatus;
                break;

              default:
                unlocked = !!adksData[pageNumber.value]?.completed;
                break;
            }
            return unlocked;
          }

          if (
            adksData.length &&
            adksData[pageNumber.value]?.completed &&
            filterNextAdks?.length >= 1
          ) {
            return true;
          }
        }
        return false;
      }
      return false;
    });
    const nextPage = pageValue => {
      let position = Number(pageValue) + Number(1);
      if (position < programDoc?.value?.data?.adks?.length) {
        const adksData = programDoc.value.data.adks;
        const nextAdks = adksData.slice(position);
        for (let i = 0; i < nextAdks.length; i++) {
          if (!nextAdks[i].status || nextAdks[i]?.isAdkDeleted) {
            position += 1;
          } else {
            break;
          }
        }
        pageNumber.value = position;
        redirectByPage(position);
      }
      // currentAdk.value = adks.value[position];
    };
    const prevPage = pageValue => {
      let position = Number(pageValue) - Number(1);
      const adksData = programDoc.value.data.adks;
      const prevAdks: any = [];
      for (let k = position; k >= 0; k--) {
        prevAdks.push(adksData[k]);
      }

      if (pageNumber.value !== 0) {
        for (let j = 0; j < prevAdks.length; j++) {
          if (!prevAdks[j].status || prevAdks[j]?.isAdkDeleted) {
            position -= 1;
          } else {
            break;
          }
        }
      }
      pageNumber.value = position;
      redirectByPage(position);
    };

    const checkPrevAdk = (adksData, position) => {
      let p = position;
      const prevAdks: any = [];
      for (let k = position; k >= 0; k--) {
        prevAdks.push(adksData[k]);
      }
      for (let j = 0; j < prevAdks.length; j++) {
        if (!prevAdks[j].status || prevAdks[j].isAdkDeleted) {
          p -= 1;
        } else {
          break;
        }
      }
      const adk = programDoc.value?.data?.adks[p];
      return adk;
    };
    // Check current adk completed or not and also check for offer adk if not redirect to previous adk
    watch(currentAdk, async () => {
      if (currentAdk.value) {
        if (getUserType.value === 'participant') {
          const adkDataValue = programDoc.value?.data?.adks?.find(
            adk => adk.name === currentAdk.value
          );
          // const offerAdkIndex = programDoc.value?.data?.adks?.findIndex(
          //   adk => adk.name === 'offer'
          // );
          const offerAdkIndex = programDoc?.value?.data?.adks?.findIndex(adk =>
            adk.fields?.find(field => field.name === 'm-offer-table')
          );
          const offerAdkMolecule = programDoc.value?.data?.adks[offerAdkIndex]?.fields.find(
            field => field.name === 'm-offer-table'
          );
          if (
            offerAdkIndex > 0 &&
            pageNumber.value >= offerAdkIndex &&
            !programDoc.value?.data?.offerDetails &&
            !isInPreviewMode.value
          ) {
            prevPage(offerAdkIndex);
          } else if (
            offerAdkIndex > 0 &&
            pageNumber.value >= offerAdkIndex &&
            programDoc.value?.data?.offerDetails &&
            !programDoc.value?.data?.adks[offerAdkIndex]?.completed &&
            !offerAdkMolecule?.value?.offerStatus &&
            !isInPreviewMode.value
          ) {
            prevPage(offerAdkIndex + 1);
          }
          const prevAdkValue = checkPrevAdk(programDoc.value?.data?.adks, pageNumber?.value - 1); // programDoc.value?.data?.adks[pageNumber.value - 1];
          if (prevAdkValue && !prevAdkValue.completed && !isInPreviewMode.value) {
            prevPage(pageNumber.value);
          }
          if (currentAdk.value === 'results') {
            const result = programDoc.value?.data?.adks?.find(elem => elem.name === 'results');
            const filterIsUpdated = programDoc.value?.data?.adks?.filter(elem => elem.completed);
            if (filterIsUpdated.length === programDoc.value?.data?.adks?.length - 1) {
              await updateCompleted({
                adkIndex: result.position,
                collection_name: 'Student',
                name: 'results',
                program_id: programDoc.value?.data?._id,
                isCompleted: true
              });
              fetchProgram();
            }
          }
        }
      } else {
        ctx.root.$router.push({
          params: {
            programId,
            page: 0
          }
        });
      }
    });

    const finishedSetup = (adkName: string) => {
      if (adkName === 'start') {
        return (
          programDoc.value?.data?.start_activity_status === ProgramActivityStatus.CompleteSetup
        );
      }
      // TODO: remove this when setup mode for Interview is implemented
      if (adkName === 'interview') {
        return true;
      }
      return (
        programDoc.value?.data?.adks?.find(adk => {
          if (adk) {
            return adk.name === adkName;
          }
          return false;
        })?.status === ProgramActivityStatus.CompleteSetup
      );
    };

    const stepsRequireSetupCompletePercentage = computed(() => {
      const totalStepsRequireSetup = needsetup.value?.length;
      let completedSteps = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const stepName of needsetup.value || []) {
        if (finishedSetup(stepName)) {
          completedSteps += 1;
        }
      }

      return Math.floor((completedSteps / totalStepsRequireSetup) * 100);
    });

    //! Doc Creation
    const containerClass = computed(() => {
      return {
        guide__page_organizer: operationMode.value === OperationMode.Setup,
        guide__page_participant: operationMode.value === OperationMode.Participant,
        guide__page_monitor: operationMode.value === OperationMode.Monitor,
        guide__preview_view: operationMode.value === OperationMode.Preview,
        'almost-done':
          stepsRequireSetupCompletePercentage.value > 51 &&
          stepsRequireSetupCompletePercentage.value < 99,
        done: stepsRequireSetupCompletePercentage.value === 100
      };
    });

    // const userDoc = ref({
    //   data: user.value
    // });
    watch(user, () => {
      userDoc.value.data = user.value;
    });

    const handleCloseOverlay = () => {
      shouldCloseDisplayOverlay.value = true;
    };

    const lastSetupPage = () => {
      for (let i = pageNumber.value; i >= 0; i--) {
        const targetAdk = adks.value[i];
        if (needsetup.value.includes(targetAdk)) {
          pageNumber.value = i;
          currentAdk.value = adks.value[i];
          redirectByPage(i);
          break;
        }
      }
    };
    const nextSetupPage = () => {
      const currentPageIndex = adks.value.indexOf(currentUnit.value);

      for (let i = currentPageIndex; i < adks.value.length; i++) {
        const targetAdk = adks.value[i];

        if (needsetup.value.includes(targetAdk)) {
          pageNumber.value = i;
          currentAdk.value = adks.value[i];
          redirectByPage(i);
          break;
        }
      }
    };
    const handleChangeStep = (action: 'last-setup' | 'next-setup' | 'last' | 'next') => {
      switch (action) {
        case 'last-setup':
          lastSetupPage();
          break;
        case 'next-setup':
          nextSetupPage();
          break;
        case 'last':
          prevPage(pageNumber.value);
          break;
        case 'next':
          nextPage(pageNumber.value);
          break;
        default:
          break;
      }
    };

    const programName = computed(() => {
      return programDoc.value?.data?.programName || 'Employer Name';
    });

    const isLoginModalShow = ref(true);
    const isSignUpModalShow = ref(false);
    function showSignUpModal() {
      isSignUpModalShow.value = true;
      isLoginModalShow.value = false;
    }

    function showSignInModal() {
      isSignUpModalShow.value = false;
      isLoginModalShow.value = true;
    }

    const isOnboarding = ref(false);
    function toggleDialog(val) {
      programNameParams.value = val;
      isNotAuthenticated.value = false;
      isOnboarding.value = val;
      localStorage.removeItem('setRoute');
    }
    if (localStorage.getItem('setRoute')) {
      localStorage.removeItem('setRoute');
    }

    const authDialog = () => {
      programNameParams.value = true;
      isOnboarding.value = true;
      localStorage.setItem('setRoute', ctx.root.$route.path);
    };

    const progressValue = ref(0);
    const animationDelay = ms => new Promise(res => setTimeout(res, ms));

    async function fetchProgress() {
      await fetchProgram();
      await animationDelay(800);
      const adksData = programDoc.value?.data?.adks;
      const availableAdks = adksData.filter(adk => !adk.isAdkDeleted);
      const completedAdks = availableAdks.filter(adk => adk.completed);
      progressValue.value = (completedAdks.length / availableAdks.length) * 100;
    }
    fetchProgress();

    const emphasize = ref(false);
    async function animateProgress(mode = 'load') {
      if (mode === 'load') {
        await animationDelay(1200);
        emphasize.value = !emphasize.value;
        await fetchProgress();
      }
      if (mode === 'complete') {
        const innerScroll = this.$refs.scrollable;
        innerScroll.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
        progressValue.value = 0;
        emphasize.value = false;
        await animationDelay(10);
        emphasize.value = true;
        fetchProgress();
      }
    }
    animateProgress();
    const closeInstructPopup = () => {
      isShowInstructPopup.value = false;
      setTimeout(() => {
        isShowCTASnackbar.value = true;
      }, snackbarTimer.value);
    };

    const isLoadSaveBtn = ref(false);
    const saveBasicUserInfo = async () => {
      isLoadSaveBtn.value = true;
      setTimeout(async () => {
        await handlePublicProgramFlow(user, programId, programDoc);
        isDisplayBasicUserInfoPopup.value = false;
      }, 2000);
    };

    return {
      currentUnit,
      getProgramDoc,
      goNext,
      completeActivity,
      maxIndex,
      pageNumber,
      fetchProgram,
      programDoc,
      adks,
      userDoc,
      needsetup,
      adksWithPreviewMode,
      isInPreviewMode,
      previewModeEnabled,
      operationMode,
      OperationMode,
      user,
      emitMolecule,
      finishedSetup,
      stepsRequireSetupCompletePercentage,
      containerClass,
      currentAdk,
      shouldCloseDisplayOverlay,
      shouldDisplayOverlay,
      isNextUnlocked,
      nextPage,
      prevPage,
      handleCloseOverlay,
      handleChangeStep,
      programName,
      getUserType,
      // fetchUserRole,
      isVisitor,
      isSaveProgress,
      programNameParams,
      isLoginModalShow,
      isSignUpModalShow,
      showSignInModal,
      showSignUpModal,
      isNotAuthenticated,
      isOnboarding,
      toggleDialog,
      authDialog,
      isDraft,
      fetchProgress,
      progressValue,
      emphasize,
      animateProgress,
      isShowInstructPopup,
      images,
      isShowCTASnackbar,
      isShowTitleSnackbar,
      snackbarTimer,
      ctaButtons,
      closeInstructPopup,
      windowWidth,
      isDisplayBasicUserInfoPopup,
      basicUserInfo,
      saveBasicUserInfo,
      isLoadSaveBtn,
      popUp
    };
  }
  // REMOVED ZENDESK FOR NOW - OCTOBER 25TH, 2023 - Derick
  // mounted() {
  //   const page = document.getElementsByTagName('head')[0];

  //   const zendesk_chat = document.createElement('script');
  //   zendesk_chat.setAttribute(
  //     'src',
  //     'https://static.zdassets.com/ekr/snippet.js?key=10f98f25-6f65-409a-a7cf-c1c1a0e2f58b'
  //   );
  //   zendesk_chat.setAttribute('id', 'ze-snippet');
  //   page?.appendChild(zendesk_chat);
  // }
});
